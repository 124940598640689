import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  isPage: any;

  constructor() { }

  ngOnInit(): void {
    // this.isPage = window.location.href.includes('invoice')
  }



}
