import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { BaseComponent } from "./view/theme/base/base.component";

const routes: Routes = [
  // { path: '', redirectTo: 'auth/login-in', pathMatch: 'full' },
  {
    path: "pages",
    component: BaseComponent,
    loadChildren: () =>
    import("../app/view/theme/theme.module").then((m) => m.ThemeModule),
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("../app/view/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "**",
    redirectTo: "error/403",
    pathMatch: "full",
  },
  { path: "", redirectTo: "/auth/login-in", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
