<div class="wrapper">
  <!-- Navbar -->
  <app-header></app-header>
  <!-- /.navbar -->
  <app-aside></app-aside>
  <div class="content-wrapper" style="min-height: unset;">
    <router-outlet></router-outlet>
  </div>

  <!-- Main Sidebar Container -->
  <!-- <app-menu></app-menu> -->
  <!-- /.content-wrapper -->
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
  <app-footer></app-footer>
</div>