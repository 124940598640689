<!-- Main Footer -->
<footer class="main-footer">
  <strong class="global-family"
    >Secured Software | All Rights Reserved and Copyrights &copy;
    <a>@ Radiant Software Technologies</a>.</strong
  >
  <div class="float-right d-none d-sm-inline-block global-family">
    <b class="global-family">Version</b> 1.0.0
  </div>
</footer>
