<!-- <div class="wrapper" *ngIf="isAuth">
  <app-header></app-header>
  <app-aside></app-aside>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>

  <aside class="control-sidebar control-sidebar-dark">
  </aside>
  <app-footer></app-footer>
</div>
<div *ngIf="!isAuth">
  <router-outlet></router-outlet>
</div> -->
<router-outlet></router-outlet>