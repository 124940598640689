<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a class="brand-link">
    <img
      src="../assets/dist/img/lanchan.png"
      alt="AdminLTE Logo"
      class="brand-image img-circle elevation-3"
      style="opacity: 0.8"
    />
    <span class="brand-text font-weight-light global-family"
      >Nirmithi Kendra</span
    >
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="../assets/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info">
        <a href="#" class="d-block">Alexander Pierce</a>
      </div>
    </div> -->

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview menu-close">
          <a href="javascript:;" class="nav-link active">
            <i class="nav-icon fa fa-rupee-sign"></i>
            <p class="global-family">
              Transfer
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a routerLink="/pages/dashboard/transfer" class="nav-link">
                <i class="nav-icon fa fa-book fa-fw"></i>
                <p class="global-family">Transfer</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/transfer-list" class="nav-link">
                <i class="fas fa-align-justify nav-icon"></i>
                <p class="global-family">Transfer listing</p>
              </a>
            </li> -->
            <li class="nav-item">
              <a routerLink="/pages/dashboard/cheque-list" class="nav-link">
                <i class="fa fa-book nav-icon"></i>
                <p class="global-family">Cheque list</p>
              </a>
            </li>

            <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/combination" class="nav-link">
                <i class="far fa-address-book nav-icon"></i>
                <p>Combination</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/fee-head" class="nav-link">
                <i class="fas fa-chart-pie nav-icon"></i>
                <p>Fee head</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/staff-list" class="nav-link">
                <i class="fas fa-list nav-icon"></i>
                <p>Staff List</p>
              </a>
            </li> -->
          </ul>
        </li>
        <li class="nav-item has-treeview menu-close">
          <a href="javascript:;" class="nav-link active">
            <i class="nav-icon fa fa-university fa-fw"></i>
            <p class="global-family">
              Account
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a routerLink="/pages/dashboard/account" class="nav-link">
                <i class="fas fa-money-check nav-icon"></i>
                <p class="global-family">Account</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview menu-close">
          <a href="javascript:;" class="nav-link active">
            <i class="nav-icon fa fa-book fa-fw"></i>
            <p class="global-family">
              Project
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/transcation" class="nav-link">
                <i class="far fa-address-card nav-icon"></i>
                <p>Transcation Details</p>
              </a>
            </li> -->
            <li class="nav-item">
              <a routerLink="/pages/dashboard/project" class="nav-link">
                <i class="nav-icon fa fa-file"></i>
                <p class="global-family">Project</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/student-id-card" class="nav-link">
                <i class="fas fa-ad nav-icon"></i>
                <p>Student Id cards</p>
              </a>
            </li> -->
          </ul>
        </li>
        <li class="nav-item has-treeview menu-close">
          <a href="javascript:;" class="nav-link active">
            <i class="nav-icon fa fa-cog fa-fw"></i>
            <p class="global-family">
              Settings
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a routerLink="/pages/dashboard/engineers" class="nav-link">
                <i class="fas fa-university nav-icon"></i>
                <p class="global-family">Engineers</p>
              </a>
            </li>

            <li class="nav-item">
              <a
                routerLink="/pages/dashboard/list-classification"
                class="nav-link"
              >
                <i class="fas fa-align-justify nav-icon"></i>
                <p class="global-family">Classification</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/details" class="nav-link">
                <i class="fas fa-user nav-icon"></i>
                <p class="global-family">User Creation</p>
              </a>
            </li>

            <li class="nav-item">
              <a routerLink="/pages/dashboard/banks-list" class="nav-link">
                <i class="fa fa-university nav-icon"></i>
                <p class="global-family">Banks list</p>
              </a>
            </li>

            <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/management" class="nav-link">
                <i class="fas fa-align-justify nav-icon"></i>
                <p>Group</p>
              </a>
            </li>

            <li class="nav-item">
              <a routerLink="/pages/dashboard/management" class="nav-link">
                <i class="fas fa-align-justify nav-icon"></i>
                <p>Back Up</p>
              </a>
            </li>

            <li class="nav-item">
              <a routerLink="/pages/dashboard/management" class="nav-link">
                <i class="fas fa-align-justify nav-icon"></i>
                <p>Activity Logs</p>
              </a>
            </li> -->
          </ul>
        </li>
        <!-- <li class="nav-item has-treeview menu-close">
          <a href="#" class="nav-link active">
            <i class="fas fa-tree fa-fw nav-icon"></i>
            <p>
              Groups
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a routerLink="/pages/dashboard/other-expenditure" class="nav-link">
                <i class="fas fa-align-left nav-icon"></i>
                <p>Members</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/details" class="nav-link">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                <p>Users</p>
              </a>
            </li>

            <li class="nav-item">
              <a routerLink="/pages/dashboard/outlet" class="nav-link">
                <i class="fas fa-wallet nav-icon"></i>
                <p>Other Fees Heads Issuing</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/cash-report" class="nav-link">
                <i class="fa fa-dot-circle-o nav-icon"></i>
                <p>Cash Records</p>
              </a>
            </li>
          </ul>
        </li> -->

        <!-- <li class="nav-item has-treeview menu-close">
          <a href="#" class="nav-link active">
            <i class="nav-icon fa fa-chart-pie fa-fw"></i>
            <p class="global-family">
              Reports
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a routerLink="/pages/dashboard/report" class="nav-link">
                <i class="fas fa-coins nav-icon"></i>
                <p class="global-family">Report</p>
              </a>
            </li> -->
        <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/report" class="nav-link">
                <i class="fa fa-dot-circle-o nav-icon"></i>
                <p>Project Wise</p>
              </a>
            </li> -->
        <!-- <li class="nav-item">
              <a routerLink="/pages/dashboard/student-excel" class="nav-link">
                <i class="fa fa-dot-circle-o nav-icon"></i>
                <p>Student Report</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/voucher" class="nav-link">
                <i class="fa fa-dot-circle-o nav-icon"></i>
                <p>Expenses Report</p>
              </a>
            </li>

            <li class="nav-item">
              <a routerLink="/pages/dashboard/outlet-list" class="nav-link">
                <i class="fa fa-dot-circle-o nav-icon"></i>
                <p>Other Fees Report</p>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/pages/dashboard/student-admission-report" class="nav-link">
                <i class="fa fa-dot-circle-o nav-icon"></i>
                <p>Admission Report</p>
              </a>
            </li> -->
        <!-- </ul>
        </li> -->
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
